import { HubbleFullSvg } from "~/assets/assets";

export default function AxisHubbleBranding() {
  return (
    <div class="flex flex-row items-center justify-center">
      <span class={` text-buttonMedium text-white `}>Powered by</span>
      <div class="w-[8px]" />
      <div class="h-[2px]" />
      <HubbleFullSvg class="h-[13px]" fill="#FFF" />
    </div>
  );
}
